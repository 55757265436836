import styled from 'styled-components';
import stickers from '@web-stories-wp/stickers';
import SvgFromUrl from './svgFromUrl';

const Noop = () => null;

const SvgWrapper = styled.div`
  ${({ baseStyles }) => baseStyles}

  position: relative;
  width: 100%;
  height: 100%;

  ${({ baseStyles, isIcon }) =>
    isIcon &&
    `
    width: ${baseStyles.height}px;
    height: ${baseStyles.height}px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  svg {
    ${({ isIcon }) =>
      !isIcon &&
      `
    position: absolute;
    top: 0;
    left: 0;
  `}

    width: 100%;
    height: ${({ isIcon }) => (isIcon ? 'auto' : '100%')};
  }
`;

function StickerElement({ element, isIcon, style, className }) {
  const { sticker } = element;

  if (!sticker) {
    return <Noop />;
  }

  const { url, type } = sticker;

  if (url) {
    return <SvgFromUrl style={style} url={url} className={className} />;
  }

  if (sticker.svg) {
    const svgElement = sticker.svg.replace(
      '<svg',
      // We need to add those attributes to the SVG directly for the SVG to display
      // properly in the AMPHTML output (since the CSS selector above doesn't work there)
      `<svg width="100%" height="100%"`
    );

    // Figma stickers have an svg property
    return (
      <SvgWrapper
        baseStyles={style}
        className="svg-wrapper"
        dangerouslySetInnerHTML={{ __html: svgElement }}
        isIcon={isIcon}
      />
    );
  }

  const StickerOutput = stickers[type]?.svg || Noop;

  return <StickerOutput style={style} className={className} />;
}

export default StickerElement;
