/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * Internal dependencies
 */
import aspectRatiosApproximatelyMatch from './aspectRatiosApproximatelyMatch';

/**
 * Choose the source URL of the smallest available size image / video wider than
 * minWidth, according to the device pixel ratio.
 *
 * @param {number} minWidth The minimum width of the thumbnail to return.
 * @param {import('@web-stories-wp/media').Resource} resource The resource.
 * @return {string} Source URL of the smallest available size media.
 */
function getSmallestUrlForWidth(minWidth, resource) {
  if (resource.sizes) {
    // For ScorePlay, we get the size from the asset then update all resources (so we don't need
    // to load every single resource to check its size), so we end up in a situation where
    // every size has the same dimensions.
    // When that happens, we check if there is a thumbnail asset available and return that.
    const areAllSizesTheSame = checkIfAllSizesAreTheSame(
      Object.values(resource.sizes)
    );

    if (areAllSizesTheSame && resource.sizes.web_stories_thumbnail) {
      return resource.sizes.web_stories_thumbnail.source_url;
    }

    const smallestMedia = Object.values(resource.sizes)
      .sort((s1, s2) => s1.width - s2.width)
      .filter((s) => aspectRatiosApproximatelyMatch(s, resource))
      .find((s) => s.width >= minWidth * window.devicePixelRatio);

    if (smallestMedia) {
      return smallestMedia.source_url;
    } else {
      return (
        resource.sizes.web_stories_thumbnail?.source_url ||
        resource.sizes.preview?.source_url ||
        resource.sizes.full?.source_url ||
        resource.src
      );
    }
  }
  return resource.src;
}

function checkIfAllSizesAreTheSame(sizes) {
  return Object.values(sizes).every(
    (size) => size.width === sizes[0].width && size.height === sizes[0].height
  );
}

export default getSmallestUrlForWidth;
