import {
  AnonymousCredential,
  BlockBlobClient,
  type BlockBlobParallelUploadOptions,
} from '@azure/storage-blob';

type File = { name: string; size: number; type: 'image' | 'video' };

type UploadToAzureProps = {
  assetUrl: string;
  presignedUrl: string;
  updateProgress: (progress) => void;
};

type UploadBufferToAzureProps = UploadToAzureProps & {
  buffer: Buffer;
  contentType: string;
  fileName: string;
  fileSize: number;
};

type UploadBase64ToAzureProps = UploadToAzureProps & {
  base64Str: string;
  contentType: string;
  fileName: string;
};

type UploadFileToAzureProps = UploadToAzureProps & {
  data: string;
  file: File;
};

const uploadBase64ToAzure = async ({
  assetUrl,
  buffer,
  contentType,
  fileName,
  fileSize,
  presignedUrl,
  updateProgress,
}: UploadBufferToAzureProps) => {
  const credentials = new AnonymousCredential();
  const blockBlobClient = new BlockBlobClient(presignedUrl, credentials);

  const options: BlockBlobParallelUploadOptions = {
    blockSize: 4 * 1024 * 1024, // 4 MiB max block size
    concurrency: 4, // maximum number of parallel transfer workers
    maxSingleShotSize: 4 * 1024 * 1024, // 4 MiB initial transfer size
    onProgress: (progress) => {
      const progressInPercentage = Math.round(
        (progress.loadedBytes / fileSize) * 100
      );

      if (updateProgress && !isNaN(progressInPercentage)) {
        updateProgress(Math.round((progress.loadedBytes / fileSize) * 100));
      }
    },
    blobHTTPHeaders: {
      blobCacheControl: 'public, max-age=31536000',
      blobContentType: contentType,
    },
  };

  await blockBlobClient.uploadBrowserData(buffer, options);

  return {
    assetUrl,
    fileName,
  };
};

export const uploadBase64StringToAzure = async ({
  base64Str,
  ...uploadProps
}: UploadBase64ToAzureProps) => {
  const buffer = Buffer.from(base64Str, 'base64');

  const upload = await uploadBase64ToAzure({
    buffer,
    fileSize: Buffer.byteLength(buffer),
    ...uploadProps,
  });

  return upload;
};

export const uploadToAzure = async ({
  data,
  file,
  ...uploadProps
}: UploadFileToAzureProps) => {
  const contentType = data.substring(data.indexOf(':') + 1, data.indexOf(';'));

  const base64 = data
    .replace('data:image/jpeg;base64,', '')
    .replace('data:image/jpg;base64,', '')
    .replace('data:image/png;base64,', '')
    .replace('data:video/mp4;base64,', '')
    .replace('data:video/quicktime;base64,', '')
    .replace(' ', '');
  const buffer = Buffer.from(base64, 'base64');

  const upload = await uploadBase64ToAzure({
    buffer,
    contentType,
    fileName: file.name,
    fileSize: file.size,
    ...uploadProps,
  });

  return upload;
};

export * from './fileToBase64';
