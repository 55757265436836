import { useMemo } from 'react';
import useApi from './useApi';
import { uploadBase64StringToAzure, uploadToAzure } from '@src/utils/upload';

const apiConfig = {
  story: '/api/app/story',
  studioStory: '/api/app/studioStory',
  studioStoryThumbnail: '/api/app/studioStoryThumbnail',
  studioStoryIcon: '/api/app/studioStoryIcon',
  studioContentBank: 'api/app/contentBankAssetDefinition',
  template: '/api/app/template',
  media: '/api/app/media',
  auth: '/api/auth/refresh',
  userInfo: '/api/app/user/userInfo',
  mediaProvider: '/api/app/mediaProvider',
  mediaSource: '/api/app/mediaSource',
  externalAssets: '/api/app/externalAssets',
  feature: '/api/app/feature',
  studioFont: '/api/app/studioFont',
  studioShapes: '/api/app/studioShape',
  studioStickers: '/api/app/studioSticker',
  studioTextSets: '/api/app/studioTextSet',
  studioClip: '/api/app/studioClip',
  studioClipThumbnail: '/api/app/studioClipThumbnail',
  studioAd: '/api/app/studioAd',
};

const createCmsApi = (api) => {
  const voloDefaultApiClient = (url) => {
    return {
      getList: (params) =>
        api.get(url, { params }).then((res) => {
          return { payload: res.data };
        }),
    };
  };

  const getPresignedUrl = async (fileName, customFileName = null) => {
    const extension = fileName.split('.').pop();
    const finalFileName = customFileName || `original.${extension}`;

    const url = `/api/app/uploads/presigned-url?fileName=${finalFileName}`;
    const response = await api.get(url);

    return response.data;
  };

  const uploadFileToAzure = async (data, file, updateProgress) => {
    const { assetUrl, presignedUrl } = await getPresignedUrl(
      file.name,
      file.name
    );

    const response = await uploadToAzure({
      assetUrl,
      data,
      file,
      presignedUrl,
      updateProgress,
    });

    return response.assetUrl;
  };

  const uploadBase64ToAzure = async (resource, updateProgress) => {
    const fileName = 'original';
    const { assetUrl, presignedUrl } = await getPresignedUrl(
      fileName,
      fileName
    );

    const response = await uploadBase64StringToAzure({
      assetUrl,
      base64Str: resource.base64Str,
      contentType: resource.mimeType,
      fileName,
      presignedUrl,
      updateProgress,
    });
    return response.assetUrl;
  };

  const storyApi = {
    get: (storyId) =>
      api.get(`${apiConfig.story}/${storyId}`).then((res) => {
        return { payload: res.data };
      }),
  };

  const studioStoryApi = {
    get: (id, storyId) =>
      api.get(`${apiConfig.studioStory}/${id}/${storyId}`).then((res) => {
        return { payload: res.data };
      }),
    save: (page) => api.post(`${apiConfig.studioStory}/save`, page),
    generatePages: (request) =>
      api.post(`${apiConfig.studioStory}/generatepages`, request),
  };

  const studioStoryThumbnailApi = {
    get: (id, storyId) =>
      api
        .get(`${apiConfig.studioStoryThumbnail}/${id}/${storyId}`)
        .then((res) => {
          return { payload: res.data };
        }),
    render: (request) =>
      api.post(`${apiConfig.studioStoryThumbnail}/render`, request),
  };

  const studioStoryIconApi = {
    get: (id, storyId) =>
      api.get(`${apiConfig.studioStoryIcon}/${id}/${storyId}`).then((res) => {
        return { payload: res.data };
      }),
    render: (request) =>
      api.post(`${apiConfig.studioStoryIcon}/render`, request),
  };

  const studioContentBankApi = {
    get: (id, contentBankAssetId) =>
      api
        .get(`${apiConfig.studioContentBank}/${id}/${contentBankAssetId}`)
        .then((res) => {
          return { payload: res.data };
        }),
    render: (request) =>
      api.post(`${apiConfig.studioContentBank}/render`, request),
  };

  const studioClipApi = {
    get: (id, clipId) =>
      api.get(`${apiConfig.studioClip}/${id}/${clipId}`).then((res) => {
        return { payload: res.data };
      }),
    render: (request) => api.post(`${apiConfig.studioClip}/render`, request),
  };

  const studioClipThumbnailApi = {
    get: (id, clipId) =>
      api
        .get(`${apiConfig.studioClipThumbnail}/${id}/${clipId}`)
        .then((res) => {
          return { payload: res.data };
        }),
    render: (request) =>
      api.post(`${apiConfig.studioClipThumbnail}/render`, request),
  };

  const studioAdApi = {
    get: (id, adId) =>
      api.get(`${apiConfig.studioAd}/${id}/${adId}`).then((res) => {
        return { payload: res.data };
      }),
    render: (request) => api.post(`${apiConfig.studioAd}/render`, request),
  };

  const templateApi = {
    _delete: (id) => api.delete(`${apiConfig.template}/${id}`),
    save: (template) =>
      api.post(`${apiConfig.template}/save`, template).then((res) => {
        return { payload: res.data };
      }),
    getList: (
      pageNumber,
      pageSize = 16,
      folderName = '',
      searchText = '',
      signal,
      assetType = null
    ) => {
      let getListUrl = `${apiConfig.template}/getListForStudio?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`;
      if (folderName) {
        getListUrl += `&folderName=${folderName}`;
      }
      if (assetType) {
        getListUrl += `&assetType=${assetType}`;
      }
      return api.get(getListUrl).then((res) => {
        return { payload: res.data || [] };
      });
    },
    getFolders: (
      pageNumber,
      pageSize = 16,
      searchText = '',
      assetType = null
    ) => {
      let getFoldersUrl = `${apiConfig.template}/templateFolders?pageNumber=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`;

      if (assetType) {
        getFoldersUrl += `&assetType=${assetType}`;
      }
      return api.get(getFoldersUrl).then((res) => {
        return { payload: res.data || [] };
      });
    },
  };

  const mediaApi = {
    upload: (media) =>
      api.post(`${apiConfig.media}/upload`, media).then((res) => {
        return { payload: res.data };
      }),
    addPoster: (poster) =>
      api.post(`${apiConfig.media}/poster`, poster).then((res) => {
        return { payload: res.data };
      }),
    _delete: (id) => api.delete(`${apiConfig.media}/${id}`),
    get: (search) =>
      api.get(`${apiConfig.media}`, { params: search }).then((res) => {
        return { payload: res.data };
      }),
  };

  const userApi = {
    get: () =>
      api.get(`${apiConfig.userInfo}`).then((res) => {
        return { payload: res.data };
      }),
  };

  const mediaProviderApi = {
    getList: () =>
      api.get(`${apiConfig.mediaProvider}`).then((res) => {
        return { payload: res.data };
      }),
    getMedias: (mediaProviderId, params) =>
      api
        .get(`${apiConfig.mediaProvider}/${mediaProviderId}/media`, { params })
        .then((res) => {
          return { payload: res.data };
        }),
    registerUsage: (mediaProviderId, { registerUsageUrl }) =>
      api.post(
        `${apiConfig.mediaProvider}/${mediaProviderId}/registerUsage${registerUsageUrl}`
      ),
    download: (mediaProviderId, { assetId }) =>
      api
        .post(
          `${apiConfig.mediaProvider}/${mediaProviderId}/download/${assetId}`
        )
        .then((res) => {
          return { url: res.data };
        }),
  };

  const externalAssetsApi = {
    getExternalAssets: (params, signal) =>
      api
        .get(`${apiConfig.externalAssets}`, {
          params,
          signal,
        })
        .then((res) => {
          return { payload: res.data };
        }),
  };

  const featureApi = {
    getFeatures: () =>
      //TODO: leave it for now, it will just return features for current tenant
      api
        .get(`${apiConfig.feature}/00000000-0000-0000-0000-000000000000`)
        .then((res) => {
          return { payload: res.data };
        }),
  };

  const mediaSourceApi = {
    _delete: (id) => api.delete(`${apiConfig.mediaSource}/externalAsset/${id}`),
  };

  const fontsApi = voloDefaultApiClient(apiConfig.studioFont);

  const shapesApi = voloDefaultApiClient(apiConfig.studioShapes);

  const stickersApi = voloDefaultApiClient(apiConfig.studioStickers);

  const textSetsApi = voloDefaultApiClient(apiConfig.studioTextSets);

  return {
    storyApi,
    studioStoryApi,
    studioStoryThumbnailApi,
    studioStoryIconApi,
    studioContentBankApi,
    templateApi,
    mediaApi,
    uploadsApi: {
      uploadFileToAzure,
      uploadBase64ToAzure,
    },
    userApi,
    mediaProviderApi,
    mediaSourceApi,
    featureApi,
    fontsApi,
    externalAssetsApi,
    shapesApi,
    stickersApi,
    textSetsApi,
    studioClipApi,
    studioClipThumbnailApi,
    studioAdApi,
  };
};

const useCmsApi = () => {
  const api = useApi();
  const cmsApi = useMemo(() => createCmsApi(api), [api]);

  return cmsApi;
};

export default useCmsApi;
