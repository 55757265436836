import elementIs from '@src/web-stories-wp/story-editor/src/elements/utils/elementIs';
import type { StoryState } from './types';
import type { Element } from '@src/web-stories-wp/story-editor/src/elements/types';
/**
 * Move multiple elements in element order on the current page.
 *
 * Background elements are ignored.
 *
 * Selection and current page is unchanged.
 *
 * @return {Object} New state
 */
function arrangeElements(
  state: StoryState,
  { elements }: { elements: Element[] }
): StoryState {
  const pageIndex = state.pages.findIndex(({ id }) => id === state.current);
  const page = state.pages[pageIndex];

  const newBackgroundElement = elements.find(
    (element) => elementIs.backgroundable(element) && element.isBackground
  );

  let pageElements = page.elements;
  let lowestPosition: number = null;

  if (newBackgroundElement) {
    pageElements = [
      newBackgroundElement,
      ...page.elements.filter(
        (element) =>
          !(elementIs.backgroundable(element) && element.isBackground)
      ),
    ];
  }

  /** Find the lowest element in the list of elements to rearrange
   * (if our elements were pasted on top of existing elements,
   * we don't want to rearrange the existing elements too) */
  for (
    let existingIndex = 1; // 0 is background
    existingIndex < pageElements.length;
    existingIndex++
  ) {
    const pageElement = pageElements[existingIndex];
    const indexInElementsToRearrange = elements.findIndex((orderedElement) =>
      isSameElement(orderedElement, pageElement)
    );

    // Ignore elements that don't need to be rearranged
    if (indexInElementsToRearrange === -1) {
      continue;
    }

    if (lowestPosition === null || existingIndex < lowestPosition) {
      lowestPosition = existingIndex;

      // We can't go lower than 1 since that's the background
      if (existingIndex === 1) {
        break;
      }
    }
  }

  const orderedElementsWithoutBackground = elements.filter(
    (element) => !elementIs.backgroundable(element) || !element.isBackground
  );

  // If the elements aren't already on the page, we add them on top of the existing elements
  if (lowestPosition === null) {
    return {
      ...state,
      pages: [
        ...state.pages.slice(0, pageIndex),
        {
          ...page,
          elements: [...pageElements, ...orderedElementsWithoutBackground],
        },
        ...state.pages.slice(pageIndex + 1),
      ],
    };
  }

  // If there are less than three elements, there's nothing to rearrange as first is bg
  if (pageElements.length < 3) {
    // Unless we specifically want to replace the bg element
    if (newBackgroundElement) {
      return {
        ...state,
        pages: [
          ...state.pages.slice(0, pageIndex),
          {
            ...page,
            elements: pageElements,
          },
          ...state.pages.slice(pageIndex + 1),
        ],
      };
    }

    return state;
  }

  const highestPosition = lowestPosition + elements.length - 1;

  let newElements = [
    ...pageElements.slice(0, lowestPosition),
    ...orderedElementsWithoutBackground,
    ...pageElements.slice(highestPosition + 1),
  ];

  const newPages = [
    ...state.pages.slice(0, pageIndex),
    {
      ...page,
      elements: newElements,
    },
    ...state.pages.slice(pageIndex + 1),
  ];

  return {
    ...state,
    pages: newPages,
  };
}

function isSameElement(elementA: Element, elementB: Element): boolean {
  if (elementIs.media(elementA) && elementIs.media(elementB)) {
    return elementA.resource.id === elementB.resource.id;
  }

  return elementA.id === elementB.id;
}

export default arrangeElements;
