/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import * as React from 'react';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type ReactNode,
} from '@web-stories-wp/react';
import styled, { css } from 'styled-components';
import { __ } from '@web-stories-wp/i18n';
import {
  DropDown,
  Text,
  themeHelpers,
  THEME_CONSTANTS,
} from '@web-stories-wp/design-system';
import Dialog from '@web-stories-wp/story-editor/src/components/dialog';
import { AdvancedDropDown } from '@web-stories-wp/story-editor/src/components/form';
import getFontWeights from '@web-stories-wp/story-editor/src/components/panels/design/textStyle/getFontWeights';
/**
 * Internal dependencies
 */
import { FigmaImportContext } from './context';
import type { TextElementFont } from '../../elements/types';
import type { FigmaFontMatch, FontPickerModalProps } from './types';

const UNRECOGNISED_FONT_NAME = 'Unrecognised font';

const Space = styled.div`
  height: ${({ space }) => space}px;
`;

const Inputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const FontWeightDropDown = styled(DropDown)`
  width: auto;
  min-width: 140px;
  flex: 1;
`;

export const focusStyle = css`
  ${({ theme }) =>
    themeHelpers.focusableOutlineCSS(
      theme.colors.border.focus,
      theme.colors.bg.secondary
    )};
`;

type FontPickerModals = {
  [figmaFontFamily: string]: {
    [figmaFontStyleOrContent: string]: {
      primaryOptions: TextElementFont[];
      options: TextElementFont[];
      onSuccess: ((match: FigmaFontMatch) => void)[];
    };
  };
};

export function FigmaImportProvider({ children }: { children: ReactNode }) {
  const [fontPickerModals, setFontPickerModals] = useState<FontPickerModals>(
    {}
  );
  const [selectedFont, setSelectedFont] = useState<TextElementFont | null>(
    null
  );
  const [selectedWeight, setSelectedWeight] = useState<string | null>(null);

  const addFontPickerModal = useCallback(
    ({ content, figmaFontName, onSuccess, ...rest }: FontPickerModalProps) =>
      setFontPickerModals((existingModals) => {
        if (figmaFontName === null) {
          const existingModalsWithUnrecognisedFont = Object.keys(
            existingModals
          ).filter((key) => key.startsWith(UNRECOGNISED_FONT_NAME)).length;

          const newModalName = `${UNRECOGNISED_FONT_NAME} ${
            existingModalsWithUnrecognisedFont + 1
          }`;

          return {
            ...existingModals,
            [newModalName]: {
              // Instead of the font style, we show the content
              // to help users identify the element the font should be added to
              [`"${content}"`]: {
                ...rest,
                onSuccess: [onSuccess],
              },
            },
          };
        }

        const exitingFontFamilyModal = existingModals[figmaFontName.family];

        if (exitingFontFamilyModal) {
          const exitingFontStyleModal =
            exitingFontFamilyModal[figmaFontName.style];

          if (exitingFontStyleModal) {
            return {
              ...existingModals,
              [figmaFontName.family]: {
                ...exitingFontFamilyModal,
                [figmaFontName.style]: {
                  ...rest,
                  onSuccess: [...exitingFontStyleModal.onSuccess, onSuccess],
                },
              },
            };
          }
        }

        return {
          ...existingModals,
          [figmaFontName.family]: {
            ...exitingFontFamilyModal,
            [figmaFontName.style]: {
              ...rest,
              onSuccess: [onSuccess],
            },
          },
        };
      }),
    []
  );

  const activeFontFamily = useMemo(() => {
    const fontFamilies = Object.keys(fontPickerModals);

    if (fontFamilies.length === 0) {
      return null;
    }

    const firstFontFamily = fontFamilies[0];

    return {
      name: firstFontFamily,
      styles: fontPickerModals[firstFontFamily],
    };
  }, [fontPickerModals]);

  const activeFontStyle = useMemo(() => {
    if (!activeFontFamily) {
      return null;
    }

    const firstFontStyleForActiveFamily = Object.keys(
      activeFontFamily.styles
    )[0];

    return {
      nameOrContent: firstFontStyleForActiveFamily,
      props: activeFontFamily.styles[firstFontStyleForActiveFamily],
    };
  }, [activeFontFamily]);

  const closeFontPickerModal = useCallback(() => {
    setFontPickerModals((existingModals) => {
      let newModals = { ...existingModals };

      if (newModals[activeFontFamily.name]) {
        delete newModals[activeFontFamily.name][activeFontStyle.nameOrContent];

        const remainingStylesForFamily = Object.keys(
          newModals[activeFontFamily.name]
        );

        if (remainingStylesForFamily.length === 0) {
          delete newModals[activeFontFamily.name];
        }
      }

      return newModals;
    });
    setSelectedFont(null);
    setSelectedWeight(null);
  }, [activeFontFamily, activeFontStyle]);

  const fontWeights = useMemo(
    () => getFontWeights(selectedFont),
    [selectedFont]
  );

  const getPrimaryOptions = useCallback(
    (primaryOptions) => {
      if (!selectedFont) {
        return primaryOptions;
      }

      if (primaryOptions.find((option) => option.id === selectedFont.id)) {
        return primaryOptions;
      }

      return [selectedFont, ...primaryOptions];
    },
    [selectedFont]
  );

  useEffect(() => {
    // Sometimes, we only need the font weight, so we can pre-select the font input
    if (!selectedFont && activeFontStyle?.props?.defaultOption) {
      setSelectedFont(activeFontStyle.props.defaultOption);
    }
  }, [activeFontStyle]);

  useEffect(() => {
    if (!selectedWeight) {
      setSelectedWeight(fontWeights[0].value);
    }
  }, [fontWeights, selectedWeight]);

  return (
    <FigmaImportContext.Provider value={{ addFontPickerModal }}>
      <Dialog
        isOpen={!!activeFontStyle}
        onClose={() => {
          closeFontPickerModal();
        }}
        ariaHideApp={false}
        primaryText="Confirm"
        // @todo disable confirm button if no font is selected
        onPrimary={() => {
          activeFontStyle?.props.onSuccess.forEach((onSuccess) => {
            onSuccess({
              font: selectedFont,
              fontWeight: parseInt(selectedWeight),
            });
          });
          closeFontPickerModal();
        }}
        onSecondary={() => {
          activeFontStyle?.props.onCancel();
          setFontPickerModals({});
          setSelectedFont(null);
          setSelectedWeight(null);
        }}
        secondaryText="Cancel import"
      >
        {activeFontStyle && (
          <>
            <Text size={THEME_CONSTANTS.TYPOGRAPHY.PRESET_SIZES.SMALL}>
              The Figma font{' '}
              <b>
                {activeFontFamily.name} ({activeFontStyle.nameOrContent}){' '}
              </b>{' '}
              could not be automatically matched to a Studio font. Please select
              a font from the dropdown below.
            </Text>
            <Space space={8} />
            <Inputs>
              {/* @todo fix arrow focus styles */}
              <AdvancedDropDown
                hasSearch
                lightMode
                onChange={(value) => setSelectedFont(value)}
                selectedId={selectedFont?.id}
                isOpen
                isInDialog
                aria-label={__('Font family', 'web-stories')}
                dropDownLabel={__('Font', 'web-stories')}
                primaryOptions={getPrimaryOptions(
                  activeFontStyle.props.primaryOptions
                )}
                options={activeFontStyle.props.options}
              />
              {selectedFont && fontWeights?.length > 0 && (
                <>
                  <FontWeightDropDown
                    ariaLabel={__('Font weight', 'web-stories')}
                    disabled={fontWeights.length === 1}
                    lightMode
                    options={fontWeights}
                    selectedValue={selectedWeight}
                    onMenuItemClick={(_evt, value) => setSelectedWeight(value)}
                    selectButtonStylesOverride={focusStyle}
                  />
                </>
              )}
            </Inputs>
          </>
        )}
      </Dialog>
      {children}
    </FigmaImportContext.Provider>
  );
}
