import { identity, useContextSelector } from '@web-stories-wp/react';
import { FigmaImportContext } from './context';
import type { FigmaImportContextType } from './types';

export function useFigmaImport(): FigmaImportContextType;
export function useFigmaImport<T>(
  selector: (state: FigmaImportContextType) => T
): T;
export function useFigmaImport<T>(
  selector: (
    state: FigmaImportContextType
  ) => T | FigmaImportContextType = identity
) {
  return useContextSelector(FigmaImportContext, selector);
}
