/*
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * External dependencies
 */
import { useCallback, useEffect, useRef } from '@web-stories-wp/react';
import getFontWeights from '@web-stories-wp/story-editor/src/components/panels/design/textStyle/getFontWeights';

/**
 * Internal dependencies
 */
import { useFont } from '../font';
import { createOptionFilter } from '../../components/form/advancedDropDown/utils';
import stripHTML from '../../utils/stripHTML';
import { useFigmaImport } from '../figmaImport';
import type {
  FigmaFontMatch,
  FigmaFontName,
  FontPickerModalProps,
} from '../figmaImport/types';
import type { TextElementFont } from '../../elements/types';

type MatchedStudioFont = { font: TextElementFont; fontWeight: number };

type MatchedFigmaFonts = {
  [figmaFontFamily: string]: {
    [figmaFontStyle: string]: MatchedStudioFont;
  };
};

function useFigmaFonts() {
  const { addFontPickerModal } = useFigmaImport();
  const fontsRef = useRef([]);

  const {
    fonts = [],
    addRecentFont,
    maybeEnqueueFontStyle,
  } = useFont(
    ({
      actions: { addRecentFont, maybeEnqueueFontStyle },
      state: { fonts },
    }) => ({
      addRecentFont,
      maybeEnqueueFontStyle,
      fonts,
    })
  );

  useEffect(() => {
    // Ensure we can access the fonts inside the promises
    fontsRef.current = fonts;
  }, [fonts]);

  const getFigmaElementFont = useCallback(
    (
      element,
      onCancel
    ): Promise<{ font: TextElementFont; fontWeight: number }> => {
      const { figmaFontName } = element;

      return new Promise((resolve) => {
        const existingMatch = getSavedMatchedFont(figmaFontName);

        const onFontMatch = async ({ font, fontWeight }: FigmaFontMatch) => {
          await maybeEnqueueFontStyle([
            {
              font,
              fontWeight,
              content: stripHTML(element.content),
            },
          ]);
          addRecentFont(font);

          if (figmaFontName) {
            saveMatchedFont(figmaFontName, { font, fontWeight });
          }

          return resolve({ font, fontWeight });
        };

        if (existingMatch) {
          return onFontMatch(existingMatch);
        }

        const fontPickerModalDefaultProps: FontPickerModalProps = {
          figmaFontName,
          primaryOptions: [],
          options: fontsRef.current,
          onSuccess: (match) => onFontMatch(match),
          onCancel,
        };

        if (!figmaFontName) {
          return addFontPickerModal({
            ...fontPickerModalDefaultProps,
            figmaFontName: null,
            content: stripHTML(element.content),
          });
        }

        const matchingFontsForFamily = createOptionFilter(fontsRef.current)(
          figmaFontName.family
        );

        if (matchingFontsForFamily.length === 0) {
          return addFontPickerModal(fontPickerModalDefaultProps);
        }

        if (matchingFontsForFamily.length === 1) {
          const bestMatch = matchingFontsForFamily[0];
          // @todo use fuzzy search for font weights?
          const fontWeights = getFontWeights(bestMatch);

          if (fontWeights.length === 1) {
            return onFontMatch({
              font: bestMatch,
              fontWeight: parseInt(fontWeights[0].value),
            });
          }

          return addFontPickerModal({
            ...fontPickerModalDefaultProps,
            primaryOptions: matchingFontsForFamily,
            defaultOption: bestMatch,
          });
        }

        if (matchingFontsForFamily.length > 1) {
          const matchingFontsForStyle = createOptionFilter(fontsRef.current)(
            `${figmaFontName.family} ${figmaFontName.style}`
          );

          if (matchingFontsForStyle.length === 0) {
            return addFontPickerModal({
              ...fontPickerModalDefaultProps,
              primaryOptions: matchingFontsForFamily,
            });
          }

          if (matchingFontsForStyle.length === 1) {
            const bestMatch = matchingFontsForStyle[0];
            const fontWeights = getFontWeights(bestMatch);

            if (fontWeights.length === 1) {
              return onFontMatch({
                font: bestMatch,
                fontWeight: parseInt(fontWeights[0].value),
              });
            }

            return addFontPickerModal({
              ...fontPickerModalDefaultProps,
              primaryOptions: matchingFontsForStyle,
              defaultOption: bestMatch,
            });
          }

          if (matchingFontsForStyle.length > 1) {
            return addFontPickerModal({
              ...fontPickerModalDefaultProps,
              primaryOptions: matchingFontsForStyle,
            });
          }
        }
      });
    },
    [addFontPickerModal, addRecentFont, maybeEnqueueFontStyle]
  );

  return { getFigmaElementFont };
}

const MATCHED_FONT_LOCAL_STORAGE_KEY = 'figmaMatchedFonts';

function getSavedMatchedFonts() {
  const localStorageItem = localStorage.getItem(MATCHED_FONT_LOCAL_STORAGE_KEY);

  if (!localStorageItem) {
    return {};
  }

  try {
    return JSON.parse(localStorageItem) as MatchedFigmaFonts;
  } catch (e) {
    return {};
  }
}

function getSavedMatchedFont(figmaFontName: FigmaFontName | null) {
  // figmaFontName can be null if the user doesn't have the font installed in Figma
  if (!figmaFontName) {
    return null;
  }

  const list = getSavedMatchedFonts();
  return list[figmaFontName.family]?.[figmaFontName.style];
}

function saveMatchedFont(
  figmaFontName: FigmaFontName | null,
  studioFont: MatchedStudioFont
) {
  if (!figmaFontName) {
    return;
  }

  const updatedList = { ...getSavedMatchedFonts() };

  if (updatedList[figmaFontName.family]) {
    updatedList[figmaFontName.family][figmaFontName.style] = studioFont;
  } else {
    updatedList[figmaFontName.family] = {
      [figmaFontName.style]: studioFont,
    };
  }

  localStorage.setItem(
    MATCHED_FONT_LOCAL_STORAGE_KEY,
    JSON.stringify(updatedList)
  );
}

export default useFigmaFonts;
